import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import { Link } from "react-router-dom";
import BASE_URL from './constant';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS
import { useTranslation } from 'react-i18next'; 

const Books = () => {
  const swiperRef = useRef();
  const swiperRefswese = useRef(null);

  const [books, setBooks] = useState([]);
  const [topics, setTopics] = useState([]);
  const [slideData, setSlideData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const {t}=useTranslation();

  useEffect(() => {
    fetchBooks(selectedTopic);
  }, [selectedTopic]);

  useEffect(() => {
    fetchBooks();
    extractTopics();
  }, []);

  const fetchBooks = async (topic) => {
    try {
      let url = `${BASE_URL}/api_home.php/library_books`;
      if (topic) {
        url += `?topic_id=${topic.id}`;
      }
      const response = await axios.get(url);
      const bookData = response.data;
      if (Array.isArray(bookData)) {
        sliderData1(bookData);
      } else {
        sliderData1([]);
        console.error('Expected an array of books');
      }
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const handleTopicChange = (topic) => {
    setSelectedTopic(topic);
  };

  const extractTopics = async () => {
    try {
      let url = `${BASE_URL}/api_home.php/topics`;
      const response = await axios.get(url);
      const allTopics = response.data;
      if (Array.isArray(allTopics)) {
        setTopics(allTopics);
      } else {
        console.error('Expected an array of topics');
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const sliderData1 = (books) => {
    const slideData = books.map((book) => ({
      image: '../images/download.png',
      title: book.book_title,
      link: book.book_link,
    }));
    setSlideData(slideData);
  };

  useEffect(() => {
    const myfitText = () => {
      const textElements = document.querySelectorAll('.slide-text-my');
      textElements.forEach((textElement) => {
        const text = textElement.innerText;
        const newtextLength = text.length;

        let fontSize;
        if (newtextLength < 10) {
          fontSize = 24;
        } else if (newtextLength > 10 && newtextLength < 20) {
          fontSize = 16;
        } else if (newtextLength >= 20 && newtextLength <= 30) {
          fontSize = 12;
        } else if (newtextLength > 30) {
          fontSize = 10;
        } else {
          fontSize = 16;
        }

        textElement.style.fontSize = `${fontSize}px`;
      });
    };

    myfitText();
    window.addEventListener('resize', myfitText);

    return () => {
      window.removeEventListener('resize', myfitText);
    };
  }, []);

  return (
    <div className='relative shadow px-8 my-5'>
      <div className='title-fil flex items-center mb-2'>
        <h1 className="text-3xl text-red-900 my-2 mr-4 flex-shrink-0">{t('explore')} </h1>
        <Swiper
          slidesPerView={3.5}
          modules={[Navigation]} 
              navigation={true}
          spaceBetween={10}
          freeMode={true}
          pagination={{ clickable: true }}
          onBeforeInit={(swiper) => {
            swiperRefswese.current = swiper;
  }}
          className="mySwiper px-5"
        >
          {/* <SwiperSlide className={`pill-slide w-full ${selectedTopic === null ? 'bg-black text-white' : ''}`} onClick={() => handleTopicChange(null)}>
            <button className='text-xs'>All</button>
          </SwiperSlide> */}
          {topics.map((topic, index) => (
            <SwiperSlide key={index} className={`pill-slide w-full ${selectedTopic && selectedTopic.id === topic.id ? 'bg-black text-white' : ''}`} onClick={() => handleTopicChange(topic)}>
              <button className='text-xs'>{topic.name}</button>
            </SwiperSlide>
          ))}
        </Swiper>
        
      </div>

      <div className='' style={{ background: 'url("../images/bookbg.png")', backgroundSize: "contain", padding: "50px 50px" }}>
        {/* <div class="absolute">
            <img src='../images/bookbg.png'/>        </div> */}
        <Swiper slidesPerView={20} modules={[Navigation]} onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}>

          {slideData.length !== 0 ?
            slideData.map((slide, index) => (
              <SwiperSlide key={index} className='mySwiperslider'>
                <Link to={slide.link}>
                <div className="relative overflow-hidden group w-full h-[183px]">
  {/* Image with hover scale */}
  <img
    src={slide.image}
    alt="Book"
    className="w-full h-full transition-transform duration-300 transform group-hover:scale-110"
  />

  {/* Text overlay with hover scale */}
  <div className="absolute top-2 left-1.5 h-full flex items-center justify-center transition-transform duration-300 transform group-hover:scale-110">
    <span className="inline-block vrttext text-[#daa520] slide-text-my tilt-neon">
      {slide.title}
    </span>
  </div>
</div>

                  {/* <img src={slide.image} alt="Book" className="w-full h-[183px] transition-transform duration-300 transform hover:scale-110" />
                  <div className="absolute top-2 left-6px h-full flex items-center justify-center">
                    <span className="inline-block vrttext text-[#daa520] slide-text-my tilt-neon">{slide.title}</span>
                  </div> */}
                </Link>
              </SwiperSlide>
            )) : <img src={'../images/download.png'} className=" h-[183px] opacity-55	" alt="Book" />
          }
        </Swiper>

        <button className='swiper-button-prev video-bnt'
          style={{ position: "absolute", left: "28px", color: "white", fontWeight: "800", top: "60%", width: "36px", height: "35.997px" }} onClick={() => swiperRef.current?.slidePrev()}>
            
          </button>
        <button className='swiper-button-next video-bnt'
          style={{ position: "absolute", right: "28px", color: "white", fontWeight: "800", top: "60%", width: "36px", height: "35.997px" }} onClick={() => swiperRef.current?.slideNext()}></button>
      </div>
    </div>
  );
}

export default Books;
