
// import React from "react";
import React, { useState, useEffect, useContext} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Navbar';
import Door from "./Door";
import Footer from "./Footer";
import "./i18n";
import {AuthContext, AuthContextProvider} from '../src/context/AuthContext';




const App=()=>{
  
    useEffect(() => {
        function handleContextMenu(e) {
          e.preventDefault(); // prevents the default right-click menu from appearing
        }
        // add the event listener to the component's root element
        const rootElement = document.getElementById('my-component');
        rootElement.addEventListener('contextmenu', handleContextMenu);
        // remove the event listener when the component is unmounted
    
        return () => {
          rootElement.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
    return(
        <>
         <AuthContextProvider>
        <Router>    
                <div id='my-component' className='app  max-w-[1600px]  m-auto'>
        <Navbar/>
        
        <Footer/>
        </div>
        </Router> 
        
        </AuthContextProvider>
        </> 
    )
    
};


  
  export default App;