
import { FaGoogle, FaEnvelope, FaLock } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import React, { useState,useContext } from 'react'
import { auth,firestore,provider } from '../firebase';
import { addDoc, collection, namedQuery } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { loginUser, handleGoogleLogin,retrieveDataFromFirestore ,updateUserData,deleteDocumentByEmail } from '../services/AuthServices';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from 'react-i18next'; 
const Login = () => {
  const { saveUserData, getUserData, emptyCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t, i18n } = useTranslation();

// const handleLogin = async (e) => {
//   e.preventDefault();
//   if (!email) {
//       alert('Please fill in the email field');
//       return;
//     }
//     if (!isValidEmail(email)) {
//       alert('Please fill in a valid email address');
//       return;
//     }
//     if (!password) {
//       alert('Please fill in the password field');
//       return;
//     }
//   try {
//     const userCredential = await signInWithEmailAndPassword(auth, email, password);
//     const user = userCredential.user;

   
//     if (user.emailVerified) {
//       console.log('User logged in successfully:', user);
//       console.log('User logged in:', user);
//       const userData = {
    
//         password: password,
//         email: email,
    
//       };
     
//       loginUser(userData)
//       .then(async (response) => {
//           if (response && response.status === 404) {
//               alert("Login failed:User not found");
//           }else if(response.status===205){
//             console.log("Login failed:invalid Credientials");
//             try {
//               const list = await retrieveDataFromFirestore();
          
//               // Replace 'desiredEmail@example.com' with the email you want to check
//               // const emailToFind = 'desiredEmail@example.com';
          
//               // Check if email is in the list
//               const foundDocument = list.find(doc => doc.email === email);
          
//               if (foundDocument) {
//                 console.log('Email found in the list:', foundDocument);
//                 updateUserData(email,user.uid,password,); 
               
//                 loginUser(userData)
//       .then(async (response) => {
//           if (response && response.status === 404) {
//               alert("Login failed:User not found");
//           }else if(response.status===205){
//             console.log("Login failed:invalid Credientials");
//             try {
//               const list = await retrieveDataFromFirestore();
          
//               // Replace 'desiredEmail@example.com' with the email you want to check
//               // const emailToFind = 'desiredEmail@example.com';
          
//               // Check if email is in the list
//               const foundDocument = list.find(doc => doc.email === email);
          
//               if (foundDocument) {
//                 console.log('Email found in the list:', foundDocument);
//                 updateUserData(email,user.uid,password,foundDocument.id); 
//                 loginUser(userData);
//               } else {
//                 console.log('Email not found in the list.');
//               }
//             } catch (error) {
//               console.error('Error checking email in the list:', error);
//             }

  
//             // alert("Login failed:invalid Credientials");
//           }else{
//             console.log(response.user_details);
//             saveUserData(response.user_details);
//               navigate('/Profile/Profile');
//           }
       
//       })
//       .catch((error) => {
//         // if (error.message === 'auth/user-not-found') {
//         //     alert('Email not found. Please check your email address.');
//         //   } else if (error.message === 'auth/wrong-password') {
//         //     alert('Invalid password. Please check your password.');
//         //   } else if (error.message === 'auth/invalid-login-credentials') {
//         //     alert('Invalid login credentials. Please check your email and password.');
//         //   } else {
//             console.error('Login failed:', error.message,error.code);
//         //   }
//       });
//               } else {
//                 console.log('Email not found in the list.');
//               }
//             } catch (error) {
//               console.error('Error checking email in the list:', error);
//             }

  
//             // alert("Login failed:invalid Credientials");
//           }else{
//             console.log(response.user_details);
//             saveUserData(response.user_details);
//               navigate('/Profile/Profile');
//           }
       
//       })
//       .catch((error) => {
//         // if (error.message === 'auth/user-not-found') {
//         //     alert('Email not found. Please check your email address.');
//         //   } else if (error.message === 'auth/wrong-password') {
//         //     alert('Invalid password. Please check your password.');
//         //   } else if (error.message === 'auth/invalid-login-credentials') {
//         //     alert('Invalid login credentials. Please check your email and password.');
//         //   } else {
//             console.error('Login failed:', error.message,error.code);
//         //   }
//       });
//       // Perform actions after successful login
//       // Modify the return value as needed
//     } else {
//       console.error('Email not verified. Please verify your email.');
//       auth.signOut();
//       // You can handle the case where email is not verified, for example, show a message to the user
//       alert('Email not verified. Please verify your email.');
//     }
   
//   } catch (error) {
//       if (error.code === 'auth/user-not-found') {
//           alert('Email not found. Please check your email address.');
//         } else if (error.code === 'auth/wrong-password') {
//           alert('Invalid password. Please check your password.');
//         } else if (error.code === 'auth/invalid-login-credentials') {
//           alert('Invalid login credentials. Please check your email and password.');
//         } else {
//           console.error('Login failed:', error.message,error.code);
//         }
//   //   console.error('Login failed:', error.message);
//   }
// };
const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
const handleLogin = async (e) => {
  e.preventDefault();
console.log({
  action: "login",
  password: password,
  email: email,
});
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // if (user.emailVerified) {
      // User is authenticated and email is verified
      handleSuccessfulLogin(user);
    // } else {
    //   // Email not verified
    //   console.error('Email not verified. Please verify your email.');
    //   auth.signOut();
    //  toast.error('Email not verified. Please verify your email.');
      
    // }
  } catch (error) {
    handleLoginError(error);
  }
};

const handleSuccessfulLogin = (user) => {
  console.log('User logged in successfully:', user);
  const userData = {
    action: "login",
    password: password,
    email: email,
  };

  loginUser(userData)
    .then((response) => {
      if (response && response.status === 404) {
        toast.error('Login failed: User not found');
      } else if (response.status === 205) {
        console.log('Login failed: Invalid Credentials');
        // Check and update Firestore data if necessary
        checkAndUpdateFirestoreData(user);
      } else {
        console.log(response.user_details);
        saveUserData(response.user_details);
        navigate('/User/User');
      }
    })
    .catch((error) => {
      handleLoginError(error);
    });
};

const checkAndUpdateFirestoreData = async (user) => {
  try {
    const list = await retrieveDataFromFirestore();
    const foundDocument = list.find((doc) => doc.email === email);

    if (foundDocument) {
      console.log('Email found in the list:', foundDocument);
      updateUserData(email, user.uid, password, foundDocument.id);
      loginUser({
        password: password,
        email: email,
      });
    } else {
      console.log('Email not found in the list.');
    }
  } catch (error) {
    console.error('Error checking email in the list:', error);
    handleLoginError(error);
  }
};

const handleLoginError = (error) => {
  if (error.code === 'auth/user-not-found') {
    toast.error('Email not found. Please check your email address.');
  } else if (error.code === 'auth/wrong-password') {
    toast.error('Invalid password. Please check your password.');
  } else if (error.code === 'auth/invalid-login-credentials') {
    toast.error('Invalid login credentials. Please check your email and password.');
  }
  else if (error.code === 'auth/invalid-email') {
    toast.error('Invalid login credential. Please check your email.');
  } 
  else if (error.code === 'auth/invalid-credential') {
    toast.error('Invalid login credential. Please check your  password.');
  }
  
  else {
    console.error('Login failed:', error.message, error.code);
  }
};

const isValidEmail = (email) => {
  // Use a regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


const handleGoogleLogins = async () => {
await  handleGoogleLogin(navigate,saveUserData);
};

  return (
    <>
      <div className="flex justify-center p-10 m-10 items-center">
     
        <div className="w-1/2">
          {/* Image */}
          <img
            src="../images/login.svg"
            alt="login"
            className="w-250 h-250 rounded-full"
          />
        </div>
        <ToastContainer />
        <div className="w-1/2">
          <h1 className="text-center text-[40px]">{t('login')}</h1>
          <div className="mt-4">
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                placeholder="Email"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* Password input with icon */}
            <div className="relative mb-5">
              <FaLock className="absolute top-3 left-3 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password} onChange={(e) => setPassword(e.target.value)} 
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
              />
              {/* Show password toggle button */}
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-3 right-3 focus:outline-none"
              >
                {showPassword ?<FaEye /> :  <FaEyeSlash />}
              </button>
            </div>
            {/* Forgot password link */}
            <Link to="/Pages/Forget" className="mb-5 block text-right">
              Forgot password?
            </Link>
            {/* Submit button */}
            <button className="w-full h-10 bg-blue-500 text-white rounded-md hover:bg-blue-600"  onClick={handleLogin}>
              Submit
            </button>
            {/* Continue with Google button */}
            <button className="w-full h-10 mt-2 flex items-center justify-center bg-red-500 text-white rounded-md hover:bg-red-600"onClick={handleGoogleLogins} >
              <FaGoogle className="mr-2" />
              Continue with Google
            </button>
            {/* Register link */}
            <p className="mb-0 mt-2 pt-1 text-sm font-semibold">
              Don't have an account?{" "}
              <Link
                to="/Signup"
                className="text-red-500 transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700"
              >
                Register
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
