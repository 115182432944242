import React from "react";

const About=()=>{
    return(
        <>
  <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold text-center mb-8">About Us</h1>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p className="text-lg">
            We are dedicated to providing a wide range of Jewish books to
            enrich the lives of our customers. Our mission is to make
            meaningful Jewish literature accessible to all, fostering a deeper
            understanding and appreciation of Jewish culture and heritage.
          </p>
        </div>
        <div className="md:w-1/2">
          <img
            src="https://via.placeholder.com/600x300"
            alt="Our Mission"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between mt-8">
        <div className="md:w-1/2">
          <img
            src="https://via.placeholder.com/600x300"
            alt="Our Team"
            className="rounded-lg shadow-lg"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
          <p className="text-lg">
            Meet the passionate individuals behind our company. Our team is
            comprised of knowledgeable experts in Jewish literature who are
            dedicated to curating the best collection of books for our
            customers. We are committed to providing exceptional service and
            sharing our love for Jewish literature with the world.
          </p>
        </div>
      </div>
    </div>
        </>
    )
};
export default About;