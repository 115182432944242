import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "./constant";
import { useTranslation } from 'react-i18next';  

const HomeDonation = () => {
  const {t}=useTranslation();
    
    useEffect(() => {
        fetchDonation();
       
      }, []);
  
      const fetchDonation = async (topic) => {
          try {
              let url = `${BASE_URL}/api_donations.php`;
              
              const response = await axios.get(url);
              const DonationData = response.data;
              console.log(response.data);
              if (Array.isArray(DonationData)) {
                  setPeople(DonationData);
                  
              } else {
                setPeople([]);
                  console.error('Expected an array of donations');
              }
          } catch (error) {
              console.error('Error fetching donations:', error);
          }
      };
    const [people,setPeople] = useState([ ]);


    return (
        <div className="bg-white bg-opacity-70 border border-gray-300 rounded-lg shadow-md p-4 m-4">
            <h2 className="text-xl font-bold mb-4">{t('people')}</h2>
            <ul className="overflow-auto h-[250px]">
                {people.map(person => (
                    <li key={person.id} className="py-2 border-b border-gray-200">
                        {person.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HomeDonation;
