import React ,{ useEffect, useState }from "react";
import { FaTelegramPlane, FaEnvelope, FaWhatsapp, FaDownload } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import BASE_URL from "../constant";
import axios from "axios";




const DownloadCatalogue = () => {
  const [catalog,setCatalog] = useState([]);
useEffect(() => {
  
  
  fetchCatalog();
}, []);
const fetchCatalog = async () => {
  try {
    let url = `${BASE_URL}/api_catelog.php`;
    const response = await axios.get(url);
    const bookData = response.data.catalog;
   

   
        setCatalog(bookData); // Replace with the appropriate state setter
    
   
    
  } catch (error) {
    console.error("Error fetching pdfs:", error);
  }
};
  const { t } = useTranslation();
  const shareOnWhatsApp = (title,link) => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(link)}`;
    window.open(whatsappUrl, '_blank');
};

const shareOnTelegram = (title,link) => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(title)}`;
    window.open(telegramUrl, '_blank');
};

const shareViaEmail = (title,link) => {
    const emailUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(link)}`;
    window.open(emailUrl, '_blank');
};
const handleDownload = (item) => {
  // Create a link element
  const link = document.createElement('a');
  link.href = item.file_path; // Set the href to the file_path
  link.download = item.title; // Set the download attribute with the title
  document.body.appendChild(link); // Append link to the body
  link.click(); // Programmatically click the link
  document.body.removeChild(link); // Remove the link after triggering the download
};
  return (
    <>
    {/* <div class="py-[40px] max-w-[400px] m-auto grid gap-4 grid-cols-2">
        
<form class="w-full ml-4 mr-4">
  <select id="count" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
    <option selected>Sukkut</option>
    <option value="Geneies">Geneies</option>
    <option value="History">History</option>
    <option value="sat">and sat</option>
  </select>
</form>
<form class="w-full ml-4 mr-4">
  <select id="ries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
    <option selected>You will sleep</option>
    <option value="Speak">Speak up</option>
    <option value="Speak">Speak well</option>
    <option value="Speak">Speak</option>
  </select>
</form>

    </div>
    <div className="m-auto text-center">
    <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 ">{t('clear')}</button>
    </div> */}
    <div className="grid grid-cols-4 gap-8 py-[40px] px-20">

    
      {catalog.map((item, index) => (
        <div key={index} className="flex flex-col items-center p-4 border rounded-lg shadow">
          <img src={item.image} alt={item.title} className="mb-4 w-full h-100 object-cover rounded" />
          
          <div className="grid grid-cols-4 gap-2  mb-2">
            <a  target="_blank" rel="noopener noreferrer" className="p-2 bg-red-900 rounded-full text-white" onClick={()=>shareOnTelegram(item.title,item.file_path)}>
              <FaTelegramPlane />
            </a>
            <a  className="p-2 bg-gray-800 rounded-full text-white"onClick={()=>shareViaEmail(item.title,item.file_path)}>
              <FaEnvelope />
            </a>
            <a target="_blank" rel="noopener noreferrer" className="p-2 bg-green-500 rounded-full text-white"onClick={()=>shareOnWhatsApp(item.title,item.file_path)}>
              <FaWhatsapp />
            </a>
            <a  target="_blank" rel="noopener noreferrer" className="p-2 bg-[#fde5c3c4] rounded-full text-black "onClick={()=>handleDownload (item)}>
              <FaDownload />
            </a>
          </div>

          <hr className="border-dotted border-gray-300 w-full mt-1 mb-2" />

          <h3 className="text-lg font-semibold">{item.title}</h3>
        </div>
      ))}
    </div>
    </>
  );
};

export default DownloadCatalogue;
