
import React, { useState, useContext, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";
import { IoIosCloseCircle } from "react-icons/io";

const Phistory = () => {
  const { getUserData } = useContext(AuthContext);
  
  // State for storing book history and order details
  const [bookHistory, setBookHistory] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null); // For storing specific order details
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [viewDetailsOrderId, setViewDetailsOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userdata = getUserData();
    GetOrder(userdata);
  }, [getUserData]);

  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?user_id=${orderData.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order successfully fetched:', response.data);
      setBookHistory(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setOrderDetails(response.data.order); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const openModal = (orderId) => {
    setViewDetailsOrderId(orderId);
    fetchOrderDetails(orderId); // Fetch the order details
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOrderDetails(null); // Clear order details when closing the modal
  };

  // Translation hook
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold mb-4">{t('phistory')}</h2>

      {/* Table */}
      <div className="relative max-h-[600px] overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-black ">
      <thead class="text-xs text-black uppercase bg-[#fdebd1]">
          <tr className="bg-[#fdebd1] text-left">
              <th className={`py-2 border-b px-4 ${getTextAlignmentClass()}`}>{t('order_no')}</th>
              <th className={`py-2 border-b px-4 ${getTextAlignmentClass()}`} >
                {t('items')}
              </th>
              <th className={`py-2 border-b px-4 ${getTextAlignmentClass()}`} >
                {t('price')} 
              </th>
              <th className={`py-2 border-b px-4 ${getTextAlignmentClass()}`}>{t('purchase_date')}</th>
              <th className={`py-2 border-b px-4 ${getTextAlignmentClass()}`}>{t('action')}</th>
            </tr>
          </thead>
          <tbody>
          {bookHistory && bookHistory.length > 0 ? (
  bookHistory.map((order) => (
    <tr key={order.id}>
      <td className="py-2 border-b px-4">{order.id}</td>
      <td className="py-2 border-b px-4">{order.details[0]?.message}</td>
      <td className="py-2 border-b px-4">${order.total_price}</td>
      <td className="py-2 border-b px-4">{order.purchaseDate}</td>
      <td className="py-2 border-b px-4">
        <button onClick={() => openModal(order.id)} className="px-4 py-1 hover:bg-[#733d17] text-white text-sm focus:outline-none focus:ring-4 rounded-lg shadow bg-[#3b200f]">
          {t('view_details')}
        </button>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="5" className="text-center py-4">
      No Activity Found
    </td>
  </tr>
)}

          </tbody>
        </table>
      </div>

      {/* Modal for displaying order details */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded shadow-lg w-[60%]">
        <div className="flex justify-between items-center mt-4">
        <h2 className="text-lg font-bold mb-2">Order Details</h2>
        <button
    onClick={closeModal}
    className="bg-red-700 text-white px-4 mb-2 py-2 rounded"
  >
    <IoIosCloseCircle />

  </button>
           
              </div>
            {orderDetails ? (
              <div>
              <div className="grid grid-cols-2 border-b-2 bg-[#fdebd1] py-4 text-center gap-4 mb-4">
              <p>Order Number</p>
                <p>Items </p>
              </div>
              <div className="grid grid-cols-2 border-b-2 py-6 text-center gap-4">
              <p>{orderDetails.id}</p>
                <p><ul>
            {orderDetails.details && orderDetails.details.length > 0 ? ( // Check if books exists and has items
              orderDetails.details.map((book) => (
                <li key={book.id}>Subscription: {book.title} </li>
              ))
              
            ) : (
              <li>No items found in this order.</li> // Fallback if no books are found
            )}
          </ul> </p>
              </div>
              <div className="grid grid-cols-2 bg-[#fdebd1] border-b-2 py-4 text-center gap-4 mb-4">
              <p>Total Price</p>
                <p>Purchase Date</p>
              </div>
              <div className="grid grid-cols-2 border-b-2 py-6 text-center gap-4 mb-4">
              <p>${orderDetails.total_price}</p>
                <p>{orderDetails.purchaseDate}</p>
              </div>

              </div>
            ) : (
              <p>Loading order details...</p>
            )}
           
          </div>
        </div>
      )}
    </div>
  );
};

export default Phistory;
