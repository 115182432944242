import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import BASE_URL from '../constant';
import { Interweave, Markup } from 'interweave';


const Donate = () => {
  const [name, setName] = useState('');
  const [reason, setReason] = useState('');
  const [amount, setAmount] = useState('');
  const [content,setContent]=useState('');
  useEffect(() => {
    fetchDonationstext();
    // fetchDonations();
    
}, []);
  const [isVolunteer, setIsVolunteer] = useState(false); // State to track if "נתנדב עי" checkbox is checked
  const fetchDonationstext = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api_donations.php/donation_text`);
        console.log(response.data);
        setContent(response.data.donation_text);
    } catch (error) {
        console.error("Error fetching topics:", error);
    }
};
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleVolunteerCheck = (e) => {
    setIsVolunteer(e.target.checked);
    // Clear name field when volunteer checkbox is unchecked
    if (!e.target.checked) {
      setName('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Name:', name || 'Anonymous');
    console.log('Reason:', reason);
    console.log('Amount:', amount);
  };

  return (
    <div className="max-w-screen-xl mt-10 mx-auto flex">
  <div
  className={`flex-1 ml-4 border border-gray-300 rounded-md p-4 ${
    window.innerWidth < 800 ? "w-4/5" : "w-full"
  }`}
>
  <Interweave content={content} />
</div>

      <div className="flex-1">
        <div className="max-w-md mx-auto mt-10">
          <h2 className='text-xl'>לימוד התורה של יום ___:</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className='flex'>
              <input
                type="checkbox"
                id="c1"
                value=""
                className=""
              />
              <label htmlFor="c1" className="block text-sm font-medium text-gray-700">
                לעילוי נשמת_____
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c2"
                value=""
                className=""
              />
              <label htmlFor="c2" className="block text-sm font-medium text-gray-700">
                לזכות ______
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c3"
                value=""
                className=""
              />
              <label htmlFor="c3" className="block text-sm font-medium text-gray-700">
                לישועת________ ____
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c4"
                value=""
                className=""
              />
              <label htmlFor="c4" className="block text-sm font-medium text-gray-700">
                לרפואת _______
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c5"
                value=""
                className=""
              />
              <label htmlFor="c5" className="block text-sm font-medium text-gray-700">
                לזיווג הגון______
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c6"
                value=""
                className=""
              />
              <label htmlFor="c6" className="block text-sm font-medium text-gray-700">
                לזרע ברך ה' ____
              </label>
            </div>
            <div className='flex'>
              <input
                type="checkbox"
                id="c7"
                value=""
                className=""
                onChange={handleVolunteerCheck}
              />
              <label htmlFor="c7" className="block text-sm font-medium text-gray-700">
                נתנדב ע"י
              </label>
            </div>
            {isVolunteer && (
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Sponsored by
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
              </div>
            )}
            <div className='flex'>
              <input
                type="checkbox"
                id="c8"
                value=""
                className=""

              />
              <label htmlFor="c7" className="block text-sm font-medium text-gray-700">
                נבעילום שם.
              </label>
            </div>
            <div>
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                Amount
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <HiOutlineCurrencyDollar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="amount"
                  value={amount}
                  onChange={handleAmountChange}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Donate
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Donate;
