
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Define available languages with flag images and codes
const languages = [
  {
    img: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCAAeADIDAREAAhEBAxEB/8QAGgAAAgMBAQAAAAAAAAAAAAAAAAcFBggBBP/EADMQAAAFAwEDCQgDAAAAAAAAAAECAwQFAAYHEQgSIQkTFBUYGVeS0hYXQVhxlabTI1GT/8QAGgEAAgMBAQAAAAAAAAAAAAAAAAUDBAYCB//EADgRAAEBBQQFCQcFAAAAAAAAAAECAAMEBRESIVPRBhYxQaIUFRciUXGho7ETMlJUYZGSByNCRMH/2gAMAwEAAhEDEQA/ANfbWGWNp7HU9AM8B409p2LxoqpIK9TOHvMqgcAIXVI5QLqXUdBrbaKSqRTF08VN3/s1AinWCail+0Fkc2i4+GWkQbu0CL7ifRkR2nuUc+Xn8SfftrWas6F/OeYjJlHOk7weE5sdp7lHPl5/En37aNWdC/nPMRkxzpO8HhObcLtQco2YoGLs9gICGoCFpvtBD/WjVjQwf3PMRkxzpO8HhObd7T3KOfLz+JPv20as6F/OeYjJjnSd4PCc2uOHdoHboubJ9uQGRsJdU20+elSk3vs28Q5hEQHU3OHUEpeOnEQGls4kGisNAvX0FFWngHVFtJqe4CpazBzCbPYhCH7qiSbzZI/1tt15e2qYoYYoYZI5dunLI5Aj8T2lOwcOlebI4Rj9SLWXctyJFN09bnAdJlIdIhkDJBzY75lgDgBDGrUSiFl/I1TCIQpRdHrC0ADX3BSwSQo1tXigH1AZVGPYj2whnZAti40JN3vHaNl1Lr6/RoW1H+UcWZCt/AzWUtuQi3esjHD1S5TFvDkE4ukyqHeHEooHFFJMolPqVwlx3Uz7tmKdwE0g3k2UlSVDqq6yTVZpZNAgVtCpUai9J3kVidKiIR8mDBBBvFx93f8AyOy4DbtHYW0RWOZ0xQwxQw0RN3fadtKJI3HdETFKLFEyRHr1JATgHARKBzBqH0qw5hIiJBLl2pVOwE+jRrfO3Vy1Ad5aN96mMPEe1/vDf11PzXHYK/xOTR8rh/jH3DKrJ0Hjq9JZ3etvbQ7KEudBuj1OqnKRajdm4QBUUhAFUjqAQxllAVAD/wAhTiU2oAAA/lj+NgnYhX0GVuiTa6rwEhVK7CBUUFm64iovqy+KduH6i9Q+ordemgIrTaCd9/a0Bj+AtSTkI3KOTc/AF29MRf8ARDTUIboiKQKkQaHVRbk1Dml1QVBISEMZZTQB4Gq3MIiIdIXAQMJ+1Qitl7eTQlQBUd4Fm1UgAdzQw7t2pQiH77r1rSqLqVoKgdhNaUF5Z6e9TGHiPa/3hv66yvNcdgr/ABOTNuVw/wAY+4b0MMiY/lHiUfGX1bzx0ubcSQQk0FFFDf0UpTCIj9K4eS6MdJK3jpQA3lJA9G6TEuVmylYJ7w1hqm0zILaT2P7O2l5mFmrmuqZiVIVso1SIwKkJTlOcDCJt8o8eHwrW6OaXxOjTtbpw7SoLIN9d3cWTzOTupmpKniiKdjJzupcT+Jd2+Rt6K0nSpMMBHFmy3VOHxFeDHdS4n8S7t8jb0UdKkwwEcWbGqcPiK8GO6lxP4l3b5G3oo6VJhgI4s2NU4fEV4Md1LifxLu3yNvRR0qTDARxZsapw+Irwa1Yt5OXHGKshQWRIq/bkeO4F2V2ig4IgCahgAQ0NukAdOPwGqE0/USNmkG8g3jlICxQkVr6tPC6NuIR8l8lZJSa7m1tXnraNv//Z",
    code: "he",
    lang: "עברית",
  },
  {
    img: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCAAeADIDAREAAhEBAxEB/8QAGgABAQEAAwEAAAAAAAAAAAAAAAgDBQYJB//EADoQAAECAwUEAw8FAQAAAAAAAAIBAwQGBwAFERJYFiGT1BMYMQgJFBciIyQyNVdxkpTT4yUzNkFCQ//EABoBAQEAAwEBAAAAAAAAAAAAAAAGAQQFBwP/xAA2EQAABAEHCAkFAQAAAAAAAAAAAQIDkQQFEVNxgfASExUhMUFV0gYUFjNRVKHB4jJhcrHRYv/aAAwDAQACEQMRAD8Apbvm6ilEJYUlRE2waxVYAYxPZl4f8i3F8f8APrJvG3YmTv1fjZvIaMv7srfYx5tP3ddcO0fQ3fDtLDMxDDWUYSM6IDGMxBFFPTUJUH0wd0PgKL2BhTZRnv8A2XhCzeOWZFi+Nu4au3XdDbxtN3dDCDQuwgChQb2VlXIjFpHETB9METG8k8kMEREwQbYyj8f39ofiFBYx6ige5Jlund7OTY3N1FpvnZIYLtCF2egyEYIcj2IOGw6Av5sByvb+kQVLdjaT6TpQs2jWg1fVsp+0LNwvuhMplsnS+UklTbNOTTl0a9tFFJHs32iiNgaEaPavcCK5m0rmmKlXr/RdaRnjijEU8gbA0I0e1e4EVzNmaYqVev8AQ0jPHFGIp5A2BoRo9q9wIrmbM0xUq9f6GkZ44oxFPIOY75smNEZX3OfzFn9u8AgS9mXh2PGiiPwVPK9VN5WsZk79X4+FO8h45L+7K32MebTt4Xe+0SsRjTnhEPFOs9DEwsH0jaBGoRNpl9CbTMmeD7YjMmXDONqagywZ+Ebdw5VOIws3jV28rtN03AjmCBwHYoCB6FaEmUdifOC3l8yxvT9N7XMULHBRtjJPFP2if+twUljHoKG7kObZJuE5sOZa3TLIiRYXacMl0H4UEaKC9iSiyyaQ4jimVhcFbzKO/DdKdJloQbRLWafq2FZbTbvF50LkkqlSXjk0kQ/Rk05Rlq27KVFt9hRfjPpBrNqJ9A/ytpbPM1x4uFxoqdOFNRTzh4z6QazaifQP8rZnma48XBoqdOFNRTzh4z6QazaifQP8rZnma48XBoqdOFNRTzikqwR98XdLUM/ck1SjcD5RwAUTM6IsKYdG4qgOJD5xVRFTf6onbovLUhNKVEVog5nYYlD5pfaW4VGxvbTSWs9R6vcyHx/amoPvwoJ8g/dtrZ96tTi8UmjJt8jKYfENqag+/CgnyD92zPvVqcXhoybfIymHxGzE6VMhUJIavVCmc3b0aoOPxwetg3nT2uJxeGjZu8jKYfEa7f1W1B0P4n5rYzrlYjF4aNm7yMph8Q2/qtqDofxPzWZ1ysRi8NGzd5GUw+Ibf1W1B0P4n5rM65WIxeGjZu8jKYfEdo7qqe5ap7T2776mmnd2znCPXyzChAR5CLbbisPkjyZmzTMiAQ9nYa7/AOl+stdS02RqTlaxzuiM3vzlLVtSd82TJBnlJ2mVKSo1GXjTcJW6z1HdJUncZnlbcvrjNUWLh6L2UnTiTkD5w6z1HdJUncZnlbOuM1RYuDspOnEnIHzh1nqO6SpO4zPK2dcZqixcHZSdOJOQPnDrPUd0lSdxmeVs64zVFi4Oyk6cScgfOHWeo7pKk7jM8rZ1xmqLFwdlJ04k5A+cOs9R3SVJ3GZ5WzrjNUWLg7KTpxJyB84//9k=",
    code: "en",
    lang: "English",
  },
];

// Define available currencies with symbols and codes
const currencies = [
  { img: "₪", code: "ils", lang: "ILS (Israeli Shekel)" },
  { img: "$", code: "usd", lang: "USD (US Dollar)" },
];

// Language selection component
const LanguageSelect = ({setSelectedLang}) => {
 
  const [selectedLang, setSelectedLang1] = useState(localStorage.getItem("language") );
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (code) => {
    setSelectedLang1(code);
    setSelectedLang(code)
    
    localStorage.setItem("language", code);
    setIsOpen(false); // Close the dropdown after selection
  };



  return (
    <div className="language-selector" style={{ position: "relative" }}>
      <button onClick={() => setIsOpen(!isOpen)} style={buttonStyle}>
        <img src={languages.find((lang) => lang.code === selectedLang)?.img} alt="" style={flagStyle} />
        <span style={textContainerStyle}>
          {languages.find((lang) => lang.code === selectedLang)?.lang}
        </span>
      </button>
      {isOpen && (
        <div style={dropdownStyle}>
          {languages.map((lang) => (
            <div
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              style={dropdownItemStyle}
            >
              <img src={lang.img} alt="" style={flagStyle} />
              <span style={textContainerStyle}>{lang.lang}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Currency selection component
const CurrencySelect = ({setSelectedCurrency}) => {
  const [selectedCurrency, setSelectedCurrency1] = useState(localStorage.getItem("currency") || "ils");
  const [isOpen, setIsOpen] = useState(false);

  const handleCurrencyChange = (code) => {
    setSelectedCurrency1(code);
    setSelectedCurrency(code);
    
    setIsOpen(false);
  };

  useEffect(() => {
    const savedCurrency = localStorage.getItem("currency");
    if (savedCurrency) {
      setSelectedCurrency1(savedCurrency);
      setSelectedCurrency(savedCurrency);
    }
  }, []);

  return (
    <div className="currency-selector" style={{ position: "relative" }}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={buttonStyle}
      >
        <span style={currencySymbolStyle}>
          {currencies.find((currency) => currency.code === selectedCurrency)?.img}
        </span>
        <span>
          {currencies.find((currency) => currency.code === selectedCurrency)?.lang}
        </span>
      </button>
      {isOpen && (
        <div style={dropdownStyle}>
          {currencies.map((currency) => (
            <div
              key={currency.code}
              onClick={() => handleCurrencyChange(currency.code)}
              style={dropdownItemStyle}
            >
              <span style={currencySymbolStyle}>{currency.img}</span>
              <span>{currency.lang}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Custom styles
const buttonStyle = {
  display: "flex",
  alignItems: "center",
  padding: "8px",
  cursor: "pointer",
  backgroundColor: "#FFFFFF",
  border: "1px solid #ccc",
  borderRadius: "4px",
  color: "black",
};

const flagStyle = {
  width: "30px",
  height: "20px",
  marginRight: "8px",
};

const currencySymbolStyle = {
  marginRight: "8px",
  fontSize: "20px",
};

const dropdownStyle = {
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: "#FFFFFF",
  border: "1px solid #ccc",
  borderRadius: "4px",
  width: "100%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 1,
  marginTop: "8px",
};

const dropdownItemStyle = {
  padding: "8px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "8px", // Adds space between the image and text
  color: "black", // Sets text color to black
};

const textContainerStyle = {
  minWidth: "80px", // Set a fixed width for language text
};

// Main component that includes both selectors
const LanguageAndCurrencySelector = () => {
  const { t,i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("language") || "en");
  const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("currency") || "usd");


  useEffect(() => {
    const savedLang = localStorage.getItem("language");
    document.body.dir = i18n.dir(savedLang); // Set document direction based on current language
  }, [i18n.language]);


  const saveSelection = () => {
    localStorage.setItem("language", selectedLang);
    i18n.changeLanguage(selectedLang);
    document.body.dir = i18n.dir(selectedLang);
    localStorage.setItem("currency", selectedCurrency);
    setIsOpen(false);
  };

  return (
    <div className="language-select-container" style={{ display: 'flex', justifyContent: 'center', margin: '0 16px' }}>
    <div style={{ position: "relative", display: "inline-block" }} >
      <button onClick={() => setIsOpen(!isOpen)}  style={{ ...buttonStyle, direction: 'ltr' }} >
        {t('lang')}: {selectedLang.toUpperCase()} | {t('currency')}: { currencies.find((currency) => currency.code === selectedCurrency)?.img}
      </button>
      {isOpen && (
        <div style={dropdownStyle}>
          
          <h3 className="text-2l  mt-4 mb-2  mr-2 ml-4 text-black font-bold">{t('lang')}:</h3>

            <div className="language-select-container" style={{ display: 'flex', justifyContent: 'center', margin: '0 16px' }}>
    <LanguageSelect setSelectedLang={setSelectedLang} />
    
  </div>
         
          
  <h3 className="text-2l mt-2 mb-2 mr-2 ml-4 text-black font-bold">{t('currency')}:</h3>
            <div className="language-select-container" style={{ display: 'flex', justifyContent: 'center', margin: '0 16px' }}>
           <CurrencySelect setSelectedCurrency={setSelectedCurrency} />
          </div>
          {/* <br/> */}
          <button
  className="px-4 py-2 bg-red-900 text-white  mt-4 mb-4 rounded-lg flex items-center justify-center mx-auto"
  onClick={saveSelection}
>
  Save
</button>
        </div>
      )}
    </div>
    </div>
  );
};


export default LanguageAndCurrencySelector;

