import { FaUser, FaPhone, FaEnvelope, FaLock, FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import React, { useState,useContext } from 'react'
import { auth,firestore,provider } from '../firebase';

import { createUserWithEmailAndPassword,sendEmailVerification,RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { registerUser, handleGoogleLogin,saveUserDataToFirestore,getCountryFromIP,verifyUser } from '../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";

import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const navigate = useNavigate();
  const { saveUserData} = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showconPassword, setShowconformPassword] = useState(false);
  
 // const [user, setUser] = useState(null);
  const [contact, setContact] = useState('+1');
   
  // const [otp,setOtp] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [conpassword, setConPassword] = useState('');
  const [name, setName] = useState('');
    const handleGoogleLogins = async () => {
      await  handleGoogleLogin(navigate,saveUserData);
    };
    const isValidEmail = (email) => {
      // Use a regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };
  const handleSend = async (event) => {
    event.preventDefault();

    if (!name) {
      toast.error('Please fill in the name field');
          return;
        }
        if (!email) {
          toast.error('Please fill in the email field');
          return;
        }
        if (!isValidEmail(email)) {
          toast.error('Please fill in a valid email address');
          return;
        }
        if (!password) {
          toast.error('Please fill in the password field');
          return;
        }
      
        if (!contact) {
          toast.error('Please fill in the phone field');
          return;
        }
        
    // setHasFilled(true);
    // generateRecaptcha();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered successfully:', userCredential.user);

      // Save additional user data to Firestore
      let user = userCredential.user;
      // sendEmailVerification(user)
      // .then(() => {
      //   console.log('Email verification sent successfully');
       
      // })
      // .catch((error) => {
      //   console.error('Error sending email verification:', error);
      // });
     
        const userData = {
          action:"register",
          username: name,
          password: password,
          email: email,
          contact:  contact,
          fb_id: userCredential.user.uid,
          address:address
         
        };
       // console.log(userData);
        registerUser(userData)
          .then((response) => {
            if (response && response.status === 205) {
              console.log('Email already registered:', response.data.message);
              toast.error('Email already registered:', response.data.message);
              
            } else {
              console.log('User registered successfully:', response);
              saveUserDataToFirestore({   contact: contact, email: user.email, name: name,uid:userCredential.user.uid, });
              saveUserData(userData);
              toast.success('User registered successfully');
             navigate('/User/User');
          
            }
          })
          .catch((error) => {
            console.error('User registration failed:', error);
          });
        // registerUser(userData);
    } catch (error) {
      console.error('User registration failed:', error);
            if (error.code === 'auth/email-already-in-use') {
              toast.error('Email is already in use. Please try again with any other email .'); // Handle specific error message
            } else {
                toast.error('User registration failed. Please try again.');
            }
     
    }
  };

  return (
    <>
      <div className="flex justify-center p-10 m-10 items-center">
      <ToastContainer />
        <div className="w-1/2">
          {/* Image */}
          <img
            src="../images/login.svg"
            alt="login"
            className="w-250 h-250 rounded-full"
          />
        </div>
        <div className="w-1/2">
          <h1 className="text-center text-[40px]">Sign Up</h1>
          <div className="mt-4">
            {/* Full Name input with icon */}
            <div className="relative">
              <FaUser className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                placeholder="Full Name"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* Contact Number input with icon */}
            <div className="relative">
              <FaPhone className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                placeholder="Contact Number"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                placeholder="Email"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* Password input with icon */}
            <div className="relative">
              <FaLock className="absolute top-3 left-3 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Show password toggle button */}
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-3 right-3 focus:outline-none"
              >
                {showPassword ?<FaEye /> :  <FaEyeSlash />}
              </button>
            </div>
            {/* Confirm Password input with icon */}
            <div className="relative">
              <FaLock className="absolute top-3 left-3 text-gray-400" />
              <input
                type={showconPassword ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={(e) => setConPassword(e.target.value)}
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
              />
              {/* Show password toggle button */}
              <button
                onClick={() => setShowconformPassword(!showconPassword)}
                className="absolute top-3 right-3 focus:outline-none"
              >
                {showconPassword ?<FaEye /> :  <FaEyeSlash />}
              </button>
            </div>
            {/* Address input with icon */}
            <div className="relative">
              <FaMapMarkerAlt className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                placeholder="Address"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            {/* Submit button */}
            <button className="w-full h-10 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleSend}>
              Submit
            </button>
            {/* Login link */}
            <p className="mb-0 mt-2 pt-1 text-sm font-semibold">
              Already have an account?{" "}
              <Link
                to="/Pages/Login"
                className="text-red-500 transition duration-150 ease-in-out hover:text-red-600 focus:text-red-600 active:text-red-700"
              >
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
