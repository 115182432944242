import React, { useState, useContext, useEffect } from "react";
import { MdAccountCircle } from "react-icons/md";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import Login from "./Pages/Login";
import Forget from "./Pages/Forget";
import Signup from "./Pages/Signup";
import Collection from "./Pages/Collections";
import Product from "./Pages/Product";
import About from "./Pages/About";
import Contact from "./Pages/Contact";

import Bundles from "./Pages/Bundles";
import User from "./User/User";
import DownloadCatalogue from "./Pages/DownloadCatalogue";
import Donate from "./Pages/Donate";
import BASE_URL from "./constant";
import PDFViewer from "./Pages/PdfViewer";
import Subscriptions from "./Pages/Subscriptions";
// import PdfApp from './Pages/pdfsearch';

import { AuthContext } from "./context/AuthContext";
import Stripepayments from "./Pages/Stripe";
import ThankYouPage from "./Pages/thankyou";
import LanguageSelectorComponent from "./LanguageSelector";
import AjaxCart from "./Ajaxcart";
import { useTranslation } from "react-i18next";
import LanguageCurrencySelect from "./language-selector";
import Privacy from "./Pages/Privacy";
import Checkout from "./Pages/Checkout";
import PrivateRoute from "./privateRoute";
import WalletPage from "./User/walletPage";
import axios from "axios";


const Navbar = () => {
  const { currentUser, getUserData } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("English");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [balance, setBalance] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("language") || "en"
  );
 
  useEffect(() => {
    const svLang = localStorage.getItem("language");
    setSaveLang(svLang);
    console.log(savedLang);
    // setSymbol(svLang !== "ils" ? "$" : "₪");
    // if (items.length == 0) {
    //   setPrice(svLang !== "ils" ? product.price : product.price_is);
    // }
  });
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // Check if selectedOption is "Hebrew"
    if (selectedOption === "Hebrew") {
      document.body.classList.add("hebrew");
    } else {
      document.body.classList.remove("hebrew");
    }
  }, [selectedOption]);
  useEffect(() => {
    const userdata = getUserData();

    // Fetch wallet balance using Axios
    axios
      .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`, {
        params: { user_id: userdata.id } // Send user ID as a query parameter
      })
      .then((response) => {
        setBalance(response.data.balance); // Assuming the response contains a 'balance' field
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching wallet balance");
        setLoading(false);
      });
  }, [getUserData,currentUser]);

  const { t } = useTranslation();
  return (
    <>
      <div
        className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2"
        style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px" }}
      >
        {/* Left side logo */}
        <div className="logo">
          <img
            src="https://oizvehadar.com/api/uploads/images/logo.png"
            className="h-[80px]"
            alt="logo"
          />
        </div>

        {/* Middle navigation */}
        <div className="navigation flex-grow flex justify-center">
          <NavLink
            exact
            to="/"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110
              hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("Home")}
          </NavLink>
          <NavLink
            to="/Collections"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("books_catalogue")}
          </NavLink>
          <NavLink
            to="/Bundles"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("bundles")}
          </NavLink>
          <NavLink
            to="/Subscriptions"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("subscriptions")}
          </NavLink>
          <NavLink
            to="/Contact"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("contact")}
          </NavLink>
          <NavLink
            to="/DownloadCatalogue"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("catalogue")}
          </NavLink>
          <NavLink
            to="/Donate"
            className={`mr-4 w-[120px] h-[40px] rounded-full bg-red-900 transform hover:scale-110 hover:bg-red-950 transition-colors flex items-center justify-center text-[${
              windowWidth < 800 ? "8px" : "12px"
            }]`}
          >
            {t("donate")}
          </NavLink>
        </div>

        {!currentUser && (
          <>
            {/* <NavLink to="/Pages/Contact" className="mr-4 px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">Contact us</NavLink> */}
            <NavLink
              to="/Login"
              className="mr-2 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
            >
              <span className=" text-[12px] text-des w-auto">{t("login")}</span>
            </NavLink>
            <NavLink
              className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
              to="/Signup"
            >
              <span className="text-[12px] text-des w-auto">{t("sign")}</span>
            </NavLink>
          </>
        )}
        
        {currentUser && (
          <>
            <NavLink to="/User/User">
            
              <MdAccountCircle
                className={`${savedLang==='en'?'ml-7':'mr-7'} h-[40px] w-[40px]`}
                style={{ color: "000" }}
              />
              <div
           
           className="w-[100px] h-[25px] rounded-full bg-green-600 transform hover:scale-110 hover:bg-green-700 transition-colors flex items-center justify-center text-center text-[14px] font-bold"

          >
             {loading ? (
            <span className="balance-text">Loading...</span>
          ) : error ? (
            <span className="balance-text error">{error}</span>
          ) : (
            <span className="balance-text">${balance}</span>
          )}
         
              {/* </NavLink> */}
            </div>
            </NavLink>
          </>
        )}

      
        <div className="relative flex items-center">
          <LanguageCurrencySelect />
         
          <AjaxCart />
        </div>
      </div>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/Login" Component={Login} />
        <Route path="/Forget" Component={Forget} />
        <Route path="/Signup" Component={Signup} />
        <Route path="Collections" Component={Collection} />
        <Route path="/Product" Component={Product} />
        <Route path="/About" Component={About} />
        <Route path="/Contact" Component={Contact} />

        <Route path="/Bundles" Component={Bundles} />
        {/* <Route  path='/User' Component={User}/> */}
        <Route path="/Pages/Stripe" Component={Stripepayments} />
        <Route path="/Subscriptions" Component={Subscriptions} />
        <Route path="/Pages/Product/:id?" Component={Product} />
        <Route path="/DownloadCatalogue" Component={DownloadCatalogue} />
        <Route path="/Donate" Component={Donate} />
        <Route path="/Checkout" Component={Checkout} />
        {/* Protected PDFViewer route */}
        <Route
          path="/pdfViewer/:id?/:title?"
          element={
            <PrivateRoute>
              <PDFViewer />
            </PrivateRoute>
          }
        />

        <Route path="/User/User" Component={User} />
        <Route
          path="/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/"
          Component={ThankYouPage}
        />
        <Route path="/Privacy" Component={Privacy} />
        <Route path="/Checkout" Component={Checkout} />
        <Route path="/wallet" Component={WalletPage} />
      </Routes>
    </>
  );
};

export default Navbar;
