import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // If the user is not logged in, redirect them to the login page
  return currentUser ? children : <Navigate to="/Login" />;
};

export default PrivateRoute;
