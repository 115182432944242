import React from "react";
import { FaEnvelope } from 'react-icons/fa';

const Forget = () => {
  return (
    <>
      <div className="flex justify-center p-10 m-10 items-center">
        <div className="w-1/2">
          {/* Image */}
          <img
            src="../images/forget.svg"
            alt="login"
            className="w-250 h-250 rounded-full"
          />
        </div>
        <div className="w-1/2">
          <h1 className="text-center text-[40px]">Forget Password</h1>
          <div className="mt-4">
            {/* Email input with icon */}
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                placeholder="Enter Email"
                className="pl-10 w-full h-10 px-4 mb-2 rounded-md border border-gray-300"
              />
            </div>
           
            {/* Submit button */}
            <button className="w-full h-10 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forget;
