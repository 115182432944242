import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";
import htmlToPlainText from "../htmltotext";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";
const MyNotifications = () => {
  const { getUserData } = useContext(AuthContext);
  const [notify, setNotify] = useState([]);

  useEffect(() => {
    // Reference to the user's notifications path
    const userData = getUserData();

    const userNotifyRef = ref(db, `notify/${userData.fb_id}`);

    // Real-time listener for the user's notifications
    const MyNotify = onValue(userNotifyRef, (snapshot) => {
      const notifications = snapshot.val();
      console.log(notifications);
      if (notifications) {
        const notifyArray = Object.entries(notifications).map(
          ([key, value]) => ({
            id: key,
            ...value,
          })
        );
        setNotify(notifyArray);
      } else {
        setNotify([]); // Reset if no notifications are found
      }
    });

    // Cleanup listener on component unmount
    return () => MyNotify();
  }, []);
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === "he" ? "text-right" : "text-left";
  };
  const convertTimestampToDateTime = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp
    return date.toLocaleString(); // Format to a readable date-time string
  };
  return (
    <>
      <div className="flex justify-between mb-4"></div>

      <div className="mb-8">
        <div className="flex"></div>
      </div>

      <div className="grid grid-cols-1 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-4"> {t("notifications")}</h3>
          <div class="relative max-h-[600px] overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right text-black ">
              <thead class="text-xs text-black uppercase bg-[#fdebd1]">
                <tr className="bg-[#fdebd1] text-left">
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>
                    {t("title")}
                  </th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>
                    {t("Message")}
                  </th>

                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>
                    {t("By")}
                  </th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>
                    {t("Time")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {notify && notify.length > 0 ? (
                  notify.map((book) => (
                    <tr key={book.id} className="border-b border-black-800">
                      <td className="py-2 px-4">
                        {htmlToPlainText(book.title)}
                      </td>
                      <td className="py-2 px-4">
                        {htmlToPlainText(book.message)}
                      </td>

                      <td className="py-2 px-4">{"Admin"}</td>
                      <td className="py-2 px-4">
                        {convertTimestampToDateTime(book.timestamp)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <li>{t("noitemfound")}</li> // Fallback if no books are found
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyNotifications;
