
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBook } from 'react-icons/fa';
import { Link } from "react-router-dom";
import BASE_URL from './constant';
import { useTranslation } from 'react-i18next'; 

const BookList = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchInput, setSearchInput] = useState('');
  const [books, setBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const {t}=useTranslation();

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_home.php/list_books`);
      const bookData = response.data;
     
      if (Array.isArray(bookData)) {
        setBooks(bookData);
        extractCategories(bookData);
      } else {
        console.error('Expected an array of books');
      }
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const extractCategories = (books) => {
    const allCategories = books.reduce((acc, book) => {
      if (book.category_name && !acc.includes(book.category_name)) {
        acc.push(book.category_name);
      }
      return acc;
    }, []);
    setCategories(['All', ...allCategories]);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredBooks = books.filter(book => {
    const title = book.book_name?.toLowerCase() || '';
    const author = book.author_name?.toLowerCase() || '';  // Assuming the correct spelling is 'author'
    return (
      (selectedCategory === 'All' || book.category_name === selectedCategory) &&
      (title.includes(searchInput.toLowerCase()) ||
        author.includes(searchInput.toLowerCase()))
    );
  });

  return (
    <div className="w-full flex justify-center items-center h-[100%]">
      <div className="w-[100%] h-[350px] bg-[#2E2323] text-white rounded-lg shadow-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">{t('list_books')}</h1>
          <div className="flex items-center">
            <input
              type="text"
              placeholder={t('search_by_title')}
              className="border px-2 py-1 text-black bg-gray-200 rounded-full mr-2"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <label htmlFor="category" className="mr-2">{t('filter_by_category')}</label>
            <select
              id="category"
              className="border px-2 py-1 text-black bg-gray-200 rounded-full"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>
        <ul className='h-[250px] overflow-y-auto'>
          {filteredBooks.map(book => (
            <li key={book.id} className="flex items-center py-2 border-b">
              <FaBook className="text-gray-500 mr-2" />
              <div className="grid grid-cols-2 w-full gap-x-4">
              {/* <Link to="/pages/Product/"> */}
              <Link to={book.book_link}>
                  <h2 className="col-span-1 font-bold">{book.book_name}</h2>
                </Link>
                <p className="col-span-1 text-sm">{book.author_name ?? "none"}</p>
                {/* <p className="col-span-1 text-xs">{book.year ?? "2024"}</p> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BookList;


