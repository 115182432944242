import React from "react";
import { useTranslation } from 'react-i18next';  
import { Link } from "react-router-dom";

const Footer =()=>{
  const {t}=useTranslation();

    return(
        <>
            <footer className="bg-white rounded-lg shadow bg-[#fde5c3c4] m-4">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                    <span className="block text-sm text-black-500 sm:text-center dark:text-black-400">© 2024 <a href="" className="hover:underline">{t('Vehadar')}</a>. {t('rights')}.</span>
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-black-500 sm:mb-0 dark:text-black-400">
                        <li>
                            <Link to="/About" className="hover:underline me-4 md:me-6">{t('about_me')}</Link>
                        </li>
                        <li>
                            <Link to="/Privacy" className="hover:underline me-4 md:me-6">{t('privacy')}</Link>
                        </li>
                       
                        <li>
                            <Link to="/Contact" className="hover:underline">{t('contact')}</Link>
                        </li>
                    </ul>
                </div>                
            </div>
        </footer>
        </>
    )
}
export default Footer;