import React,{useEffect,useState,useContext} from "react";
import BASE_URL from "../constant";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import htmlToPlainText from "../htmltotext";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';


const Checkout = () => {
  const navigate = useNavigate();
  const [products,setProducts] = useState([
  ]);
  const [total,setTotal] = useState("0.00");
  const [product_cart,setProductCart] = useState([
  ]);
  const {cart, addToCart, removeFromCart, currentUser, getUserData } = useContext(AuthContext);
  const  [savedLang,setSaveLang]= useState(localStorage.getItem("currency") || "usd")
  const  [symbol,setSymbol]= useState( "$")


  useEffect(() => {
    const  svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
     console.log(savedLang);
     setSymbol(svLang !=="ils" ? "$" : "₪")
     fetchCart();
  },[]);
  const fetchCart = async () => {
    try {
   const userData = getUserData();
      const response = await axios.get(`${BASE_URL}/api_cart.php?action=detailed_cart&user_id=${userData.id}&currency=${savedLang}`);
      const bookData = response.data;
      //console.log(response.data);
     
      setProductCart(bookData.cart);
      setProducts(bookData.cart.details);

      const totalSum = bookData.cart.details.reduce((total, item) => {
        const itemPrice = item.warehouse_quantity > 0 ? item.price : 0; // Choose the price based on language
        return total + item.quantity * parseFloat(itemPrice); // Multiply quantity by price and add to total
      }, 0);
      setTotal(parseFloat(totalSum).toFixed(2));
    //  setTotal(bookData.cart.final_amount);
     
    } catch (error) {
      setProducts([]);

     
     
      setTotal("0.00");
      console.error('Error fetching books:', error);
    }
  };
  const purchase =() => {
    if(total>0){
    // e.preventDefault();
    console.log(product_cart);
    if(currentUser){
      navigate('/pages/Stripe',{state:{
        product:product_cart,
        amount:total,
        type:"cart",
        currency:savedLang
       }} );
            }else{
              toast.error(`Your need to login first`);
            }  }else{
              toast.error(`The total amount can not be processed`);
            } 
    // addBundleToCart(pro);
 
  };
  return (
    <div className="bg-gray-100 h-screen py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-semibold mb-4">Order Summary</h1>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-3/4">
            <div className="bg-white rounded-lg shadow-md p-6 mb-4">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left font-semibold">Product</th>
                    <th className="text-left font-semibold">Price</th>
                    <th className="text-left font-semibold">Quantity</th>
                    <th className="text-left font-semibold">Total</th>
                  </tr>
                </thead>
                <tbody>
                
                {products.map((product) => (
                  <tr>
                                       <td className="py-4">
                                       <div className="flex items-center">
                                         <img
                                           className="h-16 w-16 mr-4"
                                           src={product.images[0]}
                                           alt="Product image"
                                         />
                                         <span className="font-semibold">{htmlToPlainText(product.title)}</span>
                                       </div>
                                     </td>
                                     <td>
                                     <p
                                        className={`ml-4 ${
                                          product.warehouse_quantity > 0
                                            ? ""
                                            : "text-red-500"
                                        } text-sm`}
                                      >
                                        {product.warehouse_quantity > 0
                                          ? `${symbol} ${product.price}`
                                          : "Out of Stock"}
                                      </p>
                                      </td>
                                     <td className="py-4">
                                       <div className="flex items-center">
                                        
                                         <span className="text-center w-8">{product.quantity}</span>
                                        
                                       </div>
                                     </td>
                                     <td>
                                     <p
                                        className={`ml-4 ${
                                          product.warehouse_quantity > 0
                                            ? ""
                                            : "text-red-500"
                                        } text-sm`}
                                      >
                                        {product.warehouse_quantity > 0
                                          ? `${symbol} ${((product.quantity)*product.price).toFixed(2)}`
                                          : "Out of Stock"}
                                      </p>
                                      </td>
                                     {/* <td className="py-4">{ symbol} {((product.quantity)*product.price).toFixed(2)}</td> */}
                                     </tr>
                          ))}
   
               
                  
                 
                  {/* More product rows */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="md:w-1/4">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-lg font-semibold mb-4">Summary</h2>
              <div className="flex justify-between mb-2">
                <span>Subtotal</span>
                <span>{symbol} {total}</span>
              </div>
            
              <hr className="my-2" />
              <div className="flex justify-between mb-2">
                <span className="font-semibold">Total</span>
                <span className="font-semibold">{symbol} {total}</span>
              </div>
              <button className="bg-[#3b200f] text-white py-2 px-4 rounded-lg mt-4 w-full hover:bg-red-600"onClick={purchase}>
               Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
