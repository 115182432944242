import React, { useState } from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';  
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "./LoadingPopup";


const Contact=()=>{
  const {t}=useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [msg, setMsg] = useState('');
  const[loading,setLoading]=useState(false);
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
  const SimpleSend = async (e) => {
    e.preventDefault();
    if (!email) {
      alert('Please fill in the email field');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Please fill in a valid email address');
      return;
    }
    if (!name) {
      alert('Please Enter your Name ');
      return;
    }
    setLoading(true);
  console.log({email:email,name:name,Msg:name});
  const userData={
    client_name: name,
    client_email: email,
  status: "open",
    message: msg
  };
  console.log(userData);
  try {
    const response = await axios.post(`${BASE_URL}/api_contact_us.php`, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response);
    // Check if the API request was successful (status code 2xx)
    if (response.status >= 200 && response.status < 300) {
     
      setLoading(false);
      console.log('API Response:', response.data);
      // addDataToFirestore(`${name} Sent a Quick Message in 5min Chat`,);
      alert('Your message has been sent successfully. You will receive a reply via email soon.');
      // window.location.reload();
    } else {
      setLoading(false);
      console.error('API Request failed with status:', response.status);
      alert('An error occurred while processing your request. Please try again later.');
    }
  
  } catch (error) {
    setLoading(false);
    console.error('An unexpected error occurred:', error.message);
    alert('An unexpected error occurred. Please try again later. ',error.message);
  }
  };
    return(
        <>
        
      <div className="flex justify-center items-center mt-10 w-[100%] ">
      {loading && <LoadingPopup />}
      <div className=" p-4   w-[100%] flex">
        <div className="w-1/2">
          <img src="https://via.placeholder.com/400" alt="Stock" className="w-full h-auto rounded-md" />
        </div>
        <div className="w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4">{t('contact_us')}</h2>
          <div className="mb-4">
            {/* <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <span>123-456-7890</span>
            </div> */}
            <div className="flex items-center mb-2">
              <FaEnvelope className="mr-2" />
              <span>info@oizvehadar.com</span>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="mr-2" />
              <span>8 Truman Ave Spring Valley New York 10977</span>
            </div>
          </div>
          <form>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="name">{t("name")}</label>
              <input type="text" id="name"  placeholder="Name" onChange={(e) => setName(e.target.value)}  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="email">{t("email")}</label>
              <input type="email" id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="message">{t("msg")}</label>
              <textarea id="message"  placeholder="Message" onChange={(e) => setMsg(e.target.value)}  className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"></textarea>
            </div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600" onClick={SimpleSend} >{t("send")}</button>
          </form>
        </div>
      </div>
    </div>
        </>
    )
};
export default Contact;