
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BASE_URL from "../constant";
import { useTranslation } from 'react-i18next'; 
import { Markup } from "interweave";
import htmlToPlainText from "../htmltotext";

const Filters = ({ handleFilterChange }) => {
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
 
  useEffect(() => {
    // Fetch filter options (authors, categories, topics, volumes) from the API
    const fetchFilterOptions = async () => {
    
        try {
            let url = `${BASE_URL}/api_book.php/books`;
           
            const response = await axios.get(url);
            const bookData = response.data;
            if (Array.isArray(bookData)) {
                // sliderData1(bookData);
                // extractTopics(bookData);
extractCategories(bookData);
// extractvolume(bookData);
extractAuther(bookData);
            } else {
                console.error('Expected an array of books');
            }
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    };

    fetchFilterOptions();
  }, []);
  const extractCategories = (books) => {
    const allCategories = books.reduce((acc, book) => {
      if (book.category && !acc.includes(book.category)) {
        acc.push(book.category);
      }
      return acc;
    }, []);
    setCategories(['All', ...allCategories]);
  };
  // const extractvolume = (books) => {
  //   const allCategories = books.reduce((acc, book) => {
  //     if (book.volumes && !acc.includes(book.volumes)) {
  //       acc.push(book.volumes);
  //     }
  //     return acc;
  //   }, []);
  //   setVolumes(['All', ...allCategories]);
  // };
  const extractAuther = (books) => {
    const uniqueAuthors = new Set();
  
    books.forEach((book) => {
      if (book.auther) {
        uniqueAuthors.add(book.auther);
      }
    });
  
    setAuthors(['All', ...Array.from(uniqueAuthors)]);
  };
  
//   const extractTopics = (books) => {
//     const allTopics = books.reduce((acc, book) => {
//         if (book.topics) {
//             book.topics.forEach(topic => {
//                 if (!acc.includes(topic)) {
//                     acc.push(topic);
//                 }
//             });
//         }
//         return acc;
//     }, []);
//     console.log(allTopics);
//     setTopics(['All', ...allTopics]);
// };
const handleMinPriceChange = (e) => {
  setMinPrice(e.target.value);
  handleFilterChange("min_price", e.target.value);
};

const handleMaxPriceChange = (e) => {
  setMaxPrice(e.target.value);
  handleFilterChange("max_price", e.target.value);
};
const {t}=useTranslation();
  return (
    <div className="bg-[#fde5c3c4] shadow p-4">
      <h2 className="text-lg font-semibold mb-2">{t('filters')}</h2>
      {/* Author filter */}
      <label className="block mb-2">
        {t('author')}:
        <select
          className="form-select bg-[#6D3815] text-white p-4 rounded mt-1 block w-full"
          onChange={(e) => handleFilterChange("author", e.target.value)}
        >
          {/* <option value="">Select author</option> */}
          {authors.map((author) => (
            <option key={author} value={author}>
              {htmlToPlainText(author)}
            </option>
          ))}
        </select>
      </label>
      {/* Category filter */}
      <label className="block mb-2">
        {t('category')}:
        <select
          className="form-select bg-[#6D3815] text-white p-4 rounded mt-1 block w-full"
          onChange={(e) => handleFilterChange("category", e.target.value)}
        >
          {/* <option value="">Select category</option> */}
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </label>
      {/* Topics filter */}
      {/* <label className="block mb-2">
        Topics:
        <select
          className="form-select p-4 rounded mt-1 block w-full"
          onChange={(e) => handleFilterChange("topics", e.target.value)}
        > */}
          {/* <option value="">Select topic</option> */}
          {/* {topics.map((topic) => (
            <option key={topic} value={topic}>
              {topic}
            </option>
          ))} */}
        {/* </select>
      </label> */}
      {/* Price range filter */}
      <label className="block mb-2">
         {t('price_rage')}:
        <div className="flex">
          <input
            type="number"
            placeholder="Min price"
            className="form-input bg-[#6D3815] text-white p-2 w-[50%] rounded mt-1 block mr-2 rtl:ml-2 rtl:mr-0"
            value={minPrice}
            onChange={handleMinPriceChange}
          />
          <input
            type="number"
            placeholder="Max price"
            className="form-input bg-[#6D3815] text-white p-2 w-[50%] rounded mt-1 block"
            value={maxPrice}
            onChange={handleMaxPriceChange}
          />
        </div>
      </label>
      {/* isFree toggle */}    
{/* <label className="inline-flex items-center cursor-pointer">
<span className="my-4 text-[16px] text-black">{t('isfree')}:</span>
  <input type="checkbox" value="" class="sr-only peer" 
    onChange={(e) => handleFilterChange("isFree", e.target.checked)}
  />
  <div className="relative ml-2 rtl:mr-2 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
</label> */}

      {/* Volume dropdown */}
      {/* <label className="block mb-2"> */}
       {/* {t('volume')}: */}
        {/* <select
          className="form-select bg-[#6D3815] text-white p-4 rounded mt-1 block w-full"
          onChange={(e) => handleFilterChange("volume", e.target.value)}
        > */}
         {/* // <option value="">Select volume</option> */}
          {/* {volumes.map((volume) => (
            <option key={volume} value={volume}>
              {volume}
            </option>
          ))} */}
        {/* </select>
      </label> */}
    </div>
  );
};

const ProductCard = ({ id,image, title, price,symbol }) => {
  return (
    <>
    <div className="w-full bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
    <Link to={`/pages/Product/${id}`}>
    <div>
    {image ? (
        <img
          src={image}
          alt={title}
          className="h-80 w-full object-contain rounded-t-xl"
        />
      ) : (
        <svg
        fill="#000000"
        width="800px"
        height="800px"
        viewBox="0 0 32 32"
        className="h-80 w-full object-contain rounded-t-xl"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <style>{`.cls-1{fill:none;}`}</style>
        </defs>
        <title>no-image</title>
        <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z"/>
        <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z"/>
        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32"/>
      </svg>
      )}
    </div>
      {/* <img src={image} alt={title}
          className="h-80 w-full object-cover rounded-t-xl"
        /> */}
        <div className="px-4 py-3 w-full">
          {/* <span className="text-gray-400 mr-3 uppercase text-xs">Brand</span> */}
          {/* <p className="text-lg font-bold text-black truncate block capitalize"> */}
     <Markup content={title}/>
          
          {/* </p> */}
          <div className="flex items-center" style={{ direction: 'ltr' }}>
          <p className="text-lg font-semibold text-black cursor-auto my-3">
  <span className="text-2xl">{symbol}</span> {price}
</p>

            <div className="ml-auto">
            
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-bag-plus"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                />
                <path
                  d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"
                />
              </svg>
              
            </div>
          </div>
        </div>
        </Link>
    </div>
    </>
  );
};

const ProductList = ({ products,savedLang }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          id={product.id}
          image={product.images[0]}
          title={product.title}
          price={savedLang!=='ils'? product.price : product.price_is}
          symbol={savedLang!=='ils'? "$" : "₪"}
        />
      ))}
    </div>
  );
};

const Collection = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    // Fetch filter options (authors, categories, topics, volumes) from the API
    const fetchFilterOptions = async () => {
    
        try {
            let url = `${BASE_URL}/api_book.php/books`;
           
            const response = await axios.get(url);
            const bookData = response.data;
            if (Array.isArray(bookData)) {
                // sliderData1(bookData);
                setProducts(response.data);
            } else {
                console.error('Expected an array of books');
            }
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    };

    fetchFilterOptions();
  }, []);
  const fetchFilteredProducts = async (filters) => {
    try {
      // Construct API URL with filter parameters
      let url = `${BASE_URL}/api_book.php/books/`;
      if (filters) {
        const queryParams = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
        url += `?${queryParams}`;
      }

      const response = await axios.get(url);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    }
  };

  const handleFilterChange = (filterType, filterValue) => {
    // Update filters object with the new filter value
    const newFilters = { ...filters, [filterType]: filterValue };
    if (filterValue === "All") {
      delete newFilters[filterType];
  }
  if (filterValue === "0") {
    delete newFilters[filterType];
}
    setFilters(newFilters);
    // Fetch filtered products with updated filters
    fetchFilteredProducts(newFilters);
  };
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    handleFilterChange("name",e.target.value);
  };
  const {t}=useTranslation();
  const  [savedLang,setSaveLang]= useState(localStorage.getItem("currency") || "usd")
  useEffect(() => {
 const  svLang = localStorage.getItem("currency");
   setSaveLang(svLang);
   
   
  }, );

  return (
    <div>
       <div className="bg-gray-200  text-center ">
     {/* Search input */}
       <input
      type="text"
        placeholder= {t('search_book')}
        className="border absolute bg-[#000000b8] text-white	top-[34%] w-[38%] left-[32%] rounded-md px-4 py-4 focus:outline-none"
        value={searchInput}
              onChange={handleSearchInputChange}
      />
      {/* Your banner content goes here */}
      <img src="../images/banner.jpg" className='h-[400px] w-[1600px]' alt="Banner"/>
    </div>
      <div className="container mx-auto px-4 py-8 lg:flex w-full">
        <div className=" mr-4 rtl:ml-4  collection-filter">
          <Filters handleFilterChange={handleFilterChange} />
        </div>
        <div className="collection-prod">
          <ProductList products={products} savedLang={savedLang} />
        </div>
      </div>
    </div>
  );
};

export default Collection;
