import React, { useEffect, useContext, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useParams, useLocation } from 'react-router-dom';


const ThankYouPage = () => {
  const { paymentConfirmed } = useParams();



 

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg text-center">
        {paymentConfirmed ? (
          <>
            <AiOutlineCheckCircle className="text-green-500 mx-auto h-12 w-12" />
            <h2 className="text-2xl font-semibold mt-4">Payment Confirmed!</h2>
            <p className="text-gray-600 mt-2">Thank you for your purchase.</p>
          </>
        ) : (
          <>
            <AiOutlineCheckCircle className="text-gray-400 mx-auto h-12 w-12" />
            <h2 className="text-2xl font-semibold mt-4">Payment Pending</h2>
            <p className="text-gray-600 mt-2">Your payment is being processed.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
