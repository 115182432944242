
import { FiBook } from "react-icons/fi";
import React, { useRef, useState,useEffect,useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import BASE_URL from "../constant";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 
import htmlToPlainText from "../htmltotext";

const Bundles = () => {
  const [bundles, setBundles] = useState([]);
  const {cart, addBundleToCart, removeFromCart,addToCart, currentUser, getUserData } = useContext(AuthContext);
  const [symbol, setSymbol] = useState("$");
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  useEffect(() => {
    const svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
    // console.log(savedLang);
    setSymbol(svLang !== "ils" ? "$" : "₪");
    // if (items.length == 0) {
    //   setPrice(svLang !== "ils" ? product.price : product.price_is);
    // }
  });
  useEffect(() => {
    fetchBundles();
  }, []);
  const navigate = useNavigate();
  const fetchBundles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/bundlebooks`);
      const bookData = response.data;
     
      if (Array.isArray(bookData)) {
        setBundles(bookData);
        // extractCategories(bookData);
      } else {
        console.error('Expected an array of books');
      }
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const purchase = async (e,pro) => {
    e.preventDefault();
    if(currentUser){
      const data = {
          
        product: {...pro,id:`bdl-${pro.id}`,title:`Bundle :${pro.bundle_name}`},
        quantity: 1,
        price:pro.price,
      };
      addToCart(data);
      // navigate('/pages/Stripe',{state:{
      //   product:pro,
      //   amount:pro.price,
      //   type:"bundle"
      //  }} );
            }else{
              toast.error(`Your need to login first`);
            }   
    // addBundleToCart(pro);
 
  };
  const {t}=useTranslation();
  return (
    <div className="container min-h-[800px] mx-auto">
     {/* // <ToastContainer/> */}
      <h1 className="text-3xl font-bold text-center mt-8 mb-4"> {t('book_bundle')}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {bundles.map((bundle, index) => (
          <div key={index} className="bg-white shadow-md p-4 rounded-lg">
          <div class="flex justify-between">
    <h1 class="text-2xl tilt-neon font-bold mb-2">{bundle.bundle_name}</h1>
    <p className="mb-2 text-3xl tilt-neon font-bold">
  <span className="text-4xl">$</span> {bundle.price}
</p>

      </div>
      <h2 class="mb-2 tilt-neon text-gray-600">{bundle.percentage}% {t('OFF')}</h2>
      <div className="grid grid-cols-2 mt-4 gap-2 h-[120px] overflow-y-auto">
  {bundle.books.map((book, index) => (
    <div key={index} className="flex">
      <img src={book.images[0]} alt={book.title} className="w-10 h-10 mr-2" />
      <div>
        <p>{htmlToPlainText(book.title)}</p>
        {/* <p className="text-gray-600">{book.price}</p> */}
      </div>
    </div>
  ))}
</div>

            <button className="bg-red-900 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-4 w-full" onClick={(e) => purchase(e, bundle)}>
              {t('buy_bundles')}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bundles;
