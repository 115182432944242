
import React, { useState,useEffect } from "react";
const LoadingPopup = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-white p-4 rounded-md shadow-md z-50">
        <p className="text-lg font-semibold">Processing...</p>
        <div className="mt-2">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
  export default LoadingPopup;  