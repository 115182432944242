function convertToHebrewNumerals(number) {
    if (number <= 0 || number > 10000) {
        return "Number out of supported range (1–10,000)";
    }

    const hebrewNumerals = {
        1: 'א', 2: 'ב', 3: 'ג', 4: 'ד', 5: 'ה', 6: 'ו', 7: 'ז', 8: 'ח', 9: 'ט',
        10: 'י', 20: 'כ', 30: 'ל', 40: 'מ', 50: 'נ', 60: 'ס', 70: 'ע', 80: 'פ', 90: 'צ',
        100: 'ק', 200: 'ר', 300: 'ש', 400: 'ת', 500: 'ך', 600: 'ם', 700: 'ן', 800: 'ף', 900: 'ץ',
        1000: 'א׳', 2000: 'ב׳', 3000: 'ג׳', 4000: 'ד׳'
    };

    let result = '';
    let remaining = number;

    // Handle thousands
    if (remaining >= 1000) {
        const thousands = Math.floor(remaining / 1000);
        result += hebrewNumerals[thousands * 1000] ? hebrewNumerals[thousands * 1000] : ''; // Add Hebrew numeral for thousands
        result += '׳'; // Geresh symbol for thousands
        remaining %= 1000;
    }

    // Handle hundreds
    if (remaining >= 100) {
        const hundreds = Math.floor(remaining / 100) * 100;
        if (hebrewNumerals[hundreds]) {
            result += hebrewNumerals[hundreds];
        }
        remaining %= 100;
    }

    // Handle tens and special cases for 15 and 16
    if (remaining >= 10) {
        const tens = Math.floor(remaining / 10) * 10;
        if (hebrewNumerals[tens]) {
            result += hebrewNumerals[tens];
        }
        remaining %= 10;
    }

    // Special cases for 15 and 16
    if (remaining === 15) {
        result += 'טו';
        remaining = 0;
    } else if (remaining === 16) {
        result += 'טז';
        remaining = 0;
    }

    // Handle units
    if (remaining > 0) {
        if (hebrewNumerals[remaining]) {
            result += hebrewNumerals[remaining];
        }
    }

    // Return result
    
    return result;
}

export default convertToHebrewNumerals;
