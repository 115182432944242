
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languageDetector = new LanguageDetector();

// Custom detection method to prioritize local storage for the saved language
languageDetector.addDetector({
  name: 'customLocalStorageDetector',
  lookup() {
    return localStorage.getItem('language') || undefined;
  },
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: 'he',
    lng: localStorage.getItem('language') || 'he', // Set initial language from localStorage or default to Hebrew
    detection: {
      order: ['customLocalStorageDetector', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // Not needed for React
    },
  });

export default i18n;
